<template>
	<div id="dorm">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">宿舍管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content">
				<template v-if="teacherIndex==1">	
					<!-- 宿管安排 -->
					<div class="suguan" v-if="teacherIndex==1">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total1 }}条数据
							</div>
							<div class="u-f-item">
								<el-button size="small" @click="opensuperShow">添加宿管</el-button>
							</div>
						</div>
						<el-table
							:data="Supervisor"
							height="92%"
							style="width: 100%;border: 1px solid #EEEEEE;"
							ref="Supervisor"
							v-if="teacherIndex==1"
						>
							<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
							<el-table-column label="姓名" align="center">
								<template slot-scope="scope">
									<div v-if="scope.row.user">{{scope.row.user.username}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="dormitory_name" label="管理范围" align="center"></el-table-column>
							<el-table-column label="操作" width="150" align="center">
								<template slot-scope="scope">
									<el-button @click="editsuper(scope.row)" type="text" size="small">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange1"
								@current-change="handleCurrentChange1"
								:current-page.sync="page1"
								:page-size="limit1"
								layout="total, prev, pager, next"
								:total="total1"
							></el-pagination>
						</div>
					</div>
				</template>
			</div>
			<!-- 添加宿舍楼 -->
			<el-dialog title="添加宿舍楼" :visible.sync="addtime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							宿舍楼名称
						</div>
						<el-input v-model="placeName" placeholder="请输入宿舍楼名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addtime = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑宿舍楼 -->
			<el-dialog title="编辑宿舍楼" :visible.sync="editPlace" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							宿舍楼名称
						</div>
						<el-input v-model="editPlaceName" placeholder="请输入宿舍楼名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editPlace = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitEdit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加房间 -->
			<el-dialog title="添加房间" :visible.sync="addroom" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍楼
						</div>
						<el-select v-model="school_place_id" placeholder="请选择宿舍楼"  style="width: 75%;" filterable >
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							楼层
						</div>
						<el-input v-model="floorName" placeholder="请输入楼层名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍号
						</div>
						<el-input v-model="roomName" placeholder="宿舍号" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							床位数
						</div>
						<el-input-number v-model="bed_num" :min="0" :max="10" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addroom = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitaddroom">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑房间 -->
			<el-dialog title="编辑房间" :visible.sync="editroom" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍楼
						</div>
						<el-select v-model="school_place_id" placeholder="请选择宿舍楼"  style="width: 75%;" filterable>
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							楼层
						</div>
						<el-input v-model="floorName" placeholder="请输入楼层名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍号
						</div>
						<el-input v-model="roomName" placeholder="宿舍号" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							床位数
						</div>
						<el-input-number v-model="bed_num" :min="0" :max="10" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editroom = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submiteditroom">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 添加宿管 -->
			<el-dialog :title="superTitle" :visible.sync="superShow" width="500px" :before-close="handleClose">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose" append-to-body>
						<div slot="title" class="u-f-item dialog-title">
							<div style="margin-right: 10px;">选择联系人</div>
						</div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div
										class="user-type-item u-f-justify"
										@click="typeTap(index)"
										:class="typeIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list"
										:key="index"
									>
										{{ item.name }}
										<span v-if="index + 1 < teacher_list.length">|</span>
									</div>
								</div>
								<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="teacher_list.length">
									<div
										class="left-item"
										@click="dataTap(index)"
										:class="dataIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="teacher_list.length">
									<div class="list">
										<div
											class="item"
											@click="teachTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
										>
											{{ item.username }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
							<el-button @click="reset" size="small">重置</el-button>
							<div class="u-f-item">
								<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
								<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							选择宿管
						</div>
						<div class="u-f-item input-item" style="width: 85%;">
							<el-select v-model="teachers" popper-class="el_option" style="width: 66%;">
								<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							管理范围
						</div>
						<el-select v-model="dormitory_id" placeholder="请选择宿舍楼"  style="width: 70%;" filterable multiple >
							<el-option v-for="item in dormitoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="superShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitsuper">确 定</el-button>
				</span>
			</el-dialog>
		
			<!-- 分配宿舍 -->
			<el-dialog title="分配宿舍" :visible.sync="addBedShow" width="500px" :before-close="handleClose">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="studenShow" width="800px" :before-close="handleClose" append-to-body>
						<div slot="title" class="u-f-item dialog-title">
							<div style="margin-right: 10px;">选择联系人</div>
						</div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div class="user-type-item u-f-justify" @click="gradeTap(0)"
										:class="gradeIndex == 0 ? 'active' : ''">年级
										<span>|</span>
									</div>
									<div class="user-type-item u-f-justify" @click="gradeTap(1)"
										:class="gradeIndex == 1 ? 'active' : ''">班级
									</div>
								</div>
								<el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="sousuo" @change="searchTeacher"></el-input>
							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="gradeIndex==0">
									<div
										class="left-item"
										@click="gradeDataTap(index)"
										:class="gradeDataIndex == index ? 'active' : ''"
										v-for="(item, index) in gradeList"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f2 left" v-if="gradeIndex==1">
									<div
										class="left-item"
										@click="classTap(index)"
										:class="classIndex == index ? 'active' : ''"
										v-for="(item, index) in classList"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="StudentList.length">
									<div class="list">
										<div
											class="item"
											@click="StudenTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in StudentList"
										>
											{{ item.realname }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-right">
							<!-- <el-button @click="reset" size="small">重置</el-button> -->
							<div class="u-f-item">
								<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
								<el-button type="primary" size="small" @click="studenShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							选择学生
						</div>
						<div class="u-f-item input-item" style="width: 85%;">
							<el-select v-model="studenid" popper-class="el_option" style="width: 66%;">
								<el-option style="display: none;" v-for="(item, index) in StudentList" :key="index" :label="item.realname" :value="item.id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;" @click="studenShow = true">选择</el-button>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍楼
						</div>
						<el-select v-model="school_place_id" placeholder="请选择宿舍楼"  style="width: 75%;" filterable @change="getdormsList">
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							宿舍号
						</div>
						<el-select v-model="dormId" placeholder="请选择宿舍楼"  style="width: 75%;" filterable  @change="getbedsList">
							<el-option v-for="item in dormsList" :key="item.id" :label="item.dorm" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							床位号
						</div>
						<el-select v-model="bed_id" placeholder="请选择宿舍楼"  style="width: 75%;" filterable >
							<el-option v-for="item in bedLists" :key="item.id" :label="item.bed" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addBedShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="bedbind">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 宿舍导入 -->
			<el-dialog title="宿舍导入" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
				<div class="update_box">
					<div class="update_item">
						<div>请按照模板格式进行录入</div>
						<div class="u-f-item" style="margin-top: 5px;">
							<!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
							<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
							<a href="http://hlgzx.zhiyunapi.com/uploads/20211123/a2d89097dc1c477304a74bb7096e435d.xlsx">下载模板</a>
						</div>
					</div>
					<div class="u-f" style="margin-top: 20px;">
						<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
						<div>
							<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="updateVisible=false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 宿舍导入 -->
			<el-dialog title="学生分配导入" :visible.sync="updateVisible1" width="500px" :before-close="handleClose">
				<div class="update_box">
					<div class="update_item">
						<div>请按照模板格式进行录入</div>
						<div class="u-f-item" style="margin-top: 5px;">
							<!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
							<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
							<a href="http://hlgzx.zhiyunapi.com/uploads/20211123/3bfa47204c03c55be54b98d26a9d8f5d.xlsx">下载模板</a>
						</div>
					</div>
					<div class="u-f" style="margin-top: 20px;">
						<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
						<div>
							<el-button size="mini" style="position: relative;"><input ref="filElem1" type="file" class="upload-file" @change="getFile1">点击上传</el-button>
							<div class="u-f-item u-f-jsb file_box" v-if="file1.name" style="margin-top: 10px;width: 300px;">
								<div class="u-f-item">
									<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file1.name}}
								</div>
								<i class="el-icon-circle-check" style="color: #67C23A;"></i>
								<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile1"></i>
							</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="updateVisible1=false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="gengxin1">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			updateVisible:false,
			teacherMeun: [
				{ name: '常规录入' },
				{ name: '宿管安排' },
				{ name: '学生安排' },
				{ name: '导出报表' },
				{ name: '预警提示' },
				{ name: '批量嘉奖' },
				{ name: '学生奖惩' },
				{ name: '奖惩录入' }
			],
			teacherIndex: 1,
			checkAll: false,
			isIndeterminate: true,
			building: [],
			placeList: [],
			roomList: [],
			filters: [],
			addtime: false,
			placeName: '',
			editPlaceName: '',
			editid: '',
			editPlace: false,
			total: 0,
			addroom: false,
			floorName: '',
			school_place_id:'',
			roomName:'',
			editroom:false,
			bed_num:0,
			superShow:false,
			dormitory_id:[],
			dormitoryList:[],
			teachers:'',
			selectShow:false,
			selectList:[],
			teacher_list:[],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			Supervisor:[],
			superTitle:'添加宿管',
			total2:0,
			total1:0,
			bedList:[],
			addBedShow:false,
			gradeIndex:0,
			gradeList:[],
			gradeDataIndex:0,
			classList:[],
			classIndex:-1,
			StudentList:[],
			studenShow:false,
			studenid:'',
			sousuo:'',
			dormsList:[],
			dormId:'',
			bedLists:[],
			bed_id:'',
			file: '',
			fileUrl:'',
			page:1,
			limit:10,
			file1: '',
			updateVisible1:false,
			page1:1,
			limit1:10,
			page2:1,
			limit2:10,
		};
	},
	mounted() {
		this.getPlaceList();
		this.getPlaceRoomList();
		this.teacherList();
		this.gradeData();
		this.classData();
		
		this.SupervisorDormList();
	},
	methods: {
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delFile(){
			this.file = '';
			this.$refs.filElem.value = '';
		},
		gengxin(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			console.log(formData)
			this.$api.setting.bedImport(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.getPlaceRoomList()
					this.file = '';
					this.updateVisible = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		getFile1(e){
			if(this.$refs1.filElem.files.length){
				this.file1 = this.$refs1.filElem.files[0]
			}
		},
		delFile1(){
			this.file1 = '';
			this.$refs1.filElem.value = '';
		},
		gengxin1(){
			let myfile = this.$refs1.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			console.log(formData)
			this.$api.setting.bindImport(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.getbedList()
					this.file1 = '';
					this.updateVisible1 = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getPlaceRoomList();
		},
		handleSizeChange1(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange1(val) {
			this.page1 = val;
			this.SupervisorDormList();
		},
		handleSizeChange2(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange2(val) {
			this.page2 = val;
			this.getbedList();
		},
		twacherTap(index){
			this.teacherIndex = index;
			if(this.teacherIndex==0){
				this.$router.push('/dorm')
			}else if(this.teacherIndex==1){
				this.$router.push('/dorm1')
			}else if(this.teacherIndex==2){
				this.$router.push('/dorm2')
			}else if(this.teacherIndex==3){
				this.$router.push('/daochu')
			}else if(this.teacherIndex==4){
				this.$router.push('/yujing')
			}else if(this.teacherIndex==5){
				this.$router.push('/plliang')
			}else if(this.teacherIndex==6){
				this.$router.push('/xuesheng')
			}else if(this.teacherIndex==7){
				this.$router.push('/dormAdd')
			}
		},
		handleCheckedCitiesChange(e) {
			console.log(this.building);
			this.getPlaceRoomList()
		},
		handleCheckAllChange(val) {
			let arr = [];
			for(let i in this.placeList){
				arr.push(this.placeList[i].id)
			}
			this.building = val ? arr : [];
			this.isIndeterminate = false;
			this.getPlaceRoomList()
		},
		handleClose(done) {
			done();
			this.initialize();
			this.info()
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		filterChange(filters) {
			if (filters.time_name.length === 0) {
				//重置你自己的逻辑
			} else {
				
			}
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 切换年级班级
		gradeTap(index){
			this.gradeIndex = index;
			if(index==0){
				this.gradeDataIndex = 0;
				this.classIndex = -1;
			}else{
				this.gradeDataIndex = -1;
				this.classIndex = 0;
			}
			this.getStudentList();
		},
		// 搜索人员
		searchTeacher(){
			this.getStudentList()
		},
		// 打开弹窗
		openDialog(item) {
			this.school_place_id = item.dormitory_id;
			this.floorName = item.floor;
			this.roomName = item.dorm;
			this.roomId = item.id;
			this.bed_num = item.bed_num;
			this.editroom = true;
		},
		// 初始化数据
		initialize() {},
		// 获取宿舍楼列表
		getPlaceList() {
			this.$api.setting.dormitoryList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						data[i].isbtn = false;
					}
					this.placeList = data;
					let arr = [];
					for (let i in this.placeList) {
						arr.push({
							text: this.placeList[i].name,
							value: this.placeList[i].name
						});
					}
					this.filters = arr;
				}
			});
		},
		// 添加宿舍楼
		submit() {
			this.$api.setting.addDormitory({ name: this.placeName}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.placeName = '';
					this.addtime = false;
					this.getPlaceList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 宿舍楼编辑
		editShow(item, op = '') {
			this.editPlaceName = item.name;
			this.editid = item.id;
			if (op == 'add') {
				this.addfloor = true;
			} else {
				this.editPlace = true;
			}
		},
		
		submitEdit() {
			this.$api.setting
				.editDormitory({
					name: this.editPlaceName,
					id: this.editid
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.editPlace = false;
						this.editPlaceName = '';
						this.editid = '';
						this.getPlaceList();
						this.getPlaceRoomList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除宿舍楼
		delPlace(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delDormitory({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		
		// 获取房间列表
		getPlaceRoomList() {
			let data = {};
			let data2 = {
				page:this.page,
				limit:this.limit
			};
			if(this.building.length){
				data.dormitory_id = this.building.toString()
				data2.filter = JSON.stringify(data);
				data2.op = {dormitory_id:'in'}
			}
			this.$api.setting.dormindex(data2).then(res => {
				if (res.data.code == 1) {
					this.roomList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		getdormsList(){
			let data = {
				filter:JSON.stringify({dormitory_id:this.school_place_id}),
				op:{dormitory_id:'in'}
			};
			this.$api.setting.dormindex(data).then(res => {
				if (res.data.code == 1) {
					this.dormsList = res.data.data.rows;
					this.dormId = '';
				}
			});
		},
		// 获取床位
		getbedsList(){
			let data = {
				filter:JSON.stringify({dorm:this.dormId}),
				op:{dorm:'in'}
			};
			this.$api.setting.bedIndex(data).then(res => {
				if (res.data.code == 1) {
					this.bedLists = res.data.data.rows;
					this.bed_id = '';
				}
			});
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		// 添加房间
		submitaddroom(){
			if(this.school_place_id==""||!this.school_place_id){return this.$message.error('请选择宿舍楼')}
			if(this.floorName==""||!this.floorName){return this.$message.error('请输入楼层')}
			if(this.roomName==""||!this.roomName){return this.$message.error('请输入宿舍号')}
			if(this.bed_num==""||!this.bed_num){return this.$message.error('请输入床位数')}
			let data = {
				dormitory_id:this.school_place_id,
				floor:this.floorName,
				dorm:this.roomName,
				bed_num:this.bed_num
			}
			this.$api.setting.addDorm(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.info()
					this.addroom = false;
					this.getPlaceRoomList()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		info(){
			this.school_place_id="";
			this.floorName="";
			this.roomName="";
			this.bed_num = 0;
			this.teachers = '';
			this.dormitory_id = [];
			this.bed_id = '';
			this.studenid = '';
			this.school_place_id = '';
			this.dormId = '';
		},
		// 编辑宿舍
		submiteditroom(){
			if(this.school_place_id==""||!this.school_place_id){return this.$message.error('请选择宿舍楼')}
			if(this.floorName==""||!this.floorName){return this.$message.error('请输入楼层')}
			if(this.roomName==""||!this.roomName){return this.$message.error('请输入宿舍号')}
			if(this.bed_num==""||!this.bed_num){return this.$message.error('请输入床位数')}
			let data = {
				id:this.roomId,
				dormitory_id:this.school_place_id,
				floor:this.floorName,
				dorm:this.roomName,
				bed_num:this.bed_num
			}
			this.$api.setting.editDorm(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.info()
					this.editroom = false;
					this.getPlaceRoomList()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 删除宿舍
		del_room(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delDorm({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceRoomList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		// 添加宿管
		submitsuper(){
			this.$api.setting.bindingDormitory({
				id:this.teachers,
				dormitory_id:this.dormitory_id.toString()
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('提交成功')
					this.info();
					this.SupervisorDormList()
					this.superShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		bindableList(id){
			let data = {};
			if(id){data.id = id}
			this.$api.setting.bindableList(data).then(res => {
				if (res.data.code == 1) {
					this.dormitoryList = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					// this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 重置
		reset() {
			this.teachers = '';
			this.teacherList();
		},
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else{
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.teachers = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		opensuperShow(){
			this.info()
			this.bindableList()
			this.superTitle = '添加宿管';
			this.superShow = true;
		},
		// 宿管列表
		SupervisorDormList(){
			this.$api.setting.SupervisorDormList({
				page:this.page1,
				limit:this.limit1
			}).then(res=>{
				if(res.data.code==1){
					this.Supervisor = res.data.data.rows;
					this.total1 = res.data.data.total;
				}
			})
		},
		// 编辑宿管
		editsuper(e){
			this.bindableList(e.user.id);
			this.teachers = e.user.id;
			this.dormitory_id = e.dormitory_id;
			this.superTitle = '编辑宿管';
			this.superShow = true;
		},
		// 获取住宿生列表
		getbedList(){
			this.$api.setting.bedList({
				page:this.page2,
				limit:this.limit2
			}).then(res=>{
				if(res.data.code==1){
					this.bedList = res.data.data.rows;
					this.total2 = res.data.data.total;
				}
			})
		},
		// 年级列表
		gradeData(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data.rows;
					this.gradeList = data;
					this.getStudentList();
				}
			})
		},
		// 班级列表
		classData(){
			this.$api.setting.getClassList({}).then(res=>{
				this.classList = res.data.rows;
			})
		},
		// 学生列表
		getStudentList() {
			let data = {
				semester_id:window.localStorage.getItem('semester_id')
			};
			if(this.gradeDataIndex!=-1){data.grade_id = this.gradeList[this.gradeDataIndex].id}
			if(this.classIndex!=-1){
				data.grade_id = this.classList[this.classIndex].grade_id;
				data.filter = JSON.stringify({ class_id: this.classList[this.classIndex].id })
			}
			if (this.sousuo) {
				data.keyword = this.sousuo;
			}
			this.$api.student.csdList(data).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for(let i in data){
						data[i].checked = false;
					}
					this.StudentList = data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		gradeDataTap(index){
			this.gradeDataIndex = index;
			this.getStudentList()
		},
		classTap(index){
			this.classIndex = index;
			this.getStudentList()
		},
		StudenTap(item){
			for(let i in this.StudentList){
				if(this.StudentList[i].id==item.id){
					this.StudentList[i].checked = true;
				}else{
					this.StudentList[i].checked = false;
				}
			}
			this.studenid = item.id;
		},
		// 宿舍分配
		bedbind(){
			let data = {
				student_id:this.studenid,
				bed_id:this.bed_id
			}
			this.$api.setting.bedbind(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.info()
					this.addBedShow = false;
					this.getbedList()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		}
	}
};
</script>

<style lang="scss">
#dorm {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 68vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan,.suguansss{
			padding: 25px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
